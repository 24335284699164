import React from 'react';
import './Footer.css'; // Import styles if needed

const Footer = () => {
  return (
    <footer className="footer">
      <p>Sriumayaal Traders | All Rights Reserved</p>
      <p>&copy; Garasoftwaresolutions</p> 
    </footer>
  );
};

export default Footer;
