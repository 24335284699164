import React from 'react';
import './ContactUs.css'; // Import CSS file for styles

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h2>Contact Us</h2>
      
      {/* Contact details row */}
      <div className="contact-row">
        <div className="contact-item">
          <h3>Phone</h3>
          <p>+91 9655756080</p>
        </div>
        <div className="contact-item">
          <h3>Email</h3>
          <p>sriumayaaltraders@gmail.com</p>
        </div>
        <div className="contact-item">
          <h3>Address</h3>
          <p>3/973/E5 paraipatti,sivakasi,Tamil Nadu</p>
        </div>
      </div>

      {/* Map row */}
      <div className="map">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31294.502354183845!2d77.8219838!3d9.4339992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b03e847598db757%3A0x1d56faea77485f28!2s9.4339992%2C%2077.8219838!5e0!3m2!1sen!2sin!4v1634599934762!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
        />
        <div className="directions-link">
          <a 
            href="https://www.google.com/maps/dir//9.4339992,77.8219838" 
            target="_blank" 
            rel="noopener noreferrer">
            Get Directions
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
