import React from 'react';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  const phoneNumber = '919655756080'; // Replace with your WhatsApp phone number
  
  const handleClick = () => {
   
    const url = `https://wa.me/${phoneNumber}?text=I'm%20interested%20on%20your%20crackers`;
    window.open(url, '_blank'); // Open WhatsApp chat in a new tab
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" // WhatsApp icon URL
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppButton;
