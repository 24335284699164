import React from 'react';
import { useLocation } from 'react-router-dom';
import './CheckoutPage.css';

const Checkout = () => {
  const location = useLocation();
  const { cart, selectedItems, overallTotal } = location.state;

  const handlePayment = (appName) => {
    const phoneNumber = '+919655756080'; // Recipient's number
    const amount = overallTotal.toFixed(2); // Order amount
    let paymentUrl = '';

    if (appName === 'GPay') {
      // GPay intent URL scheme
      paymentUrl = `upi://pay?pa=${phoneNumber}&pn=Sri Umayaal Traders&am=${amount}&cu=INR`;
    } else if (appName === 'PhonePe') {
      // PhonePe intent URL scheme
      paymentUrl = `upi://pay?pa=${phoneNumber}&pn=Sri Umayaal Traders&am=${amount}&cu=INR`;
    }

    // Timeout fallback technique to check if app is installed
    const timeout = setTimeout(() => {
      alert(`Please install the ${appName} app to proceed.`);
    }, 80000); // Adjust the timeout delay if needed

    // Attempt to open the payment app
    window.location.href = paymentUrl;

    // Clear timeout if the app is installed and payment is initiated
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(timeout); // App opened, clear the timeout
      }
    });
  };

  return (
    <div className="checkout-page">
      <h1>Checkout</h1>
      <h3>Total Amount to Pay: Rs {overallTotal.toFixed(2)}</h3>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {selectedItems.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{cart[item.id]}</td>
              <td>Rs {(item.discountPrice * cart[item.id]).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Payment Options */}
      <div className="payment-options">
        <h3>Select Payment Option:</h3>
        <button onClick={() => handlePayment('GPay')}>Pay with GPay</button>
        <button onClick={() => handlePayment('PhonePe')}>Pay with PhonePe</button>
        <h3 className="contact-options">Contact Number : 📞 +91 9655756080 </h3>
      </div>
    </div>
  );
};

export default Checkout;
