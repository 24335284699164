import React from 'react';
import './Header.css'; // Make sure to create this CSS file
import priceList from '../assets/Pricelist.pdf'; 
import { Link } from 'react-router-dom';// Adjust the path as needed


const Header = () => {
  return (
    <header className="header">
      <div className="contact-info">
        <span className="phone">📞 +91 9655756080</span>
        <span className="email">✉️ sriumayaaltraders@gmail.com</span>
      </div>
      <div className="logo-container">
       
        <h1 className="brand-name">Sri Umayaal Traders</h1>
      </div>
      <nav className="navbar">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/ProductPage">Products</Link></li>
          <li><Link to="/ContactUs">Contact Us</Link></li>
          <li><Link to="/SafetyTips">Safety Tips</Link></li>
        </ul>
      </nav>
      <a href={priceList} download className="download-button">Download Price List</a>
 
    </header>
  );
};

export default Header;
