import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Categories from './Categories';  // Import the Categories component
import Modal from './Modal';
import './HomePage.css';


const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const handleViewClick = (pack) => {
    setModalContent(pack);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent([]);
  };

 
  const kidsPackProducts = [
    { id: 1, name: '4” Gold Lakshmi Crackers', per: '1 Pkt' },
    { id: 2, name: '4” Lakshmi Crackers', per: '1 Pkt' },
    { id: 3, name: '3½” Lakshmi Crackers', per: '1 Pkt' },
    { id: 4, name: '5” Super Deluxe Lakshmi', per: '1 Pkt' },
    { id: 5, name: 'Red Bijili (50’s)', per: '1 Pkt' },
    { id: 6, name: 'Stripped Red Bijili (50’s)', per: '1 Pkt' },
    { id: 7, name: 'Bullet Bomb (10’s)', per: '1 Box' },
    { id: 8, name: 'Hydro Bomb (10’s)', per: '1 Box' },
    { id: 9, name: 'Paper Bomb (1/4Kg)', per: '1 Box' },
    { id: 10, name: 'Nasa Rocket', per: '1 Box' },
    { id: 11, name: 'Flower Pots Big', per: '1 Box' },
    { id: 12, name: 'Rainbow Chakkaram Big', per: '1 Box' },
    { id: 13, name: '1½” Twinkling Star', per: '1 Box' },
    { id: 14, name: '10 cm Electric Sparklers', per: '1 Box' },
    { id: 15, name: '12 cm Electric Sparklers', per: '1 Box' },
    { id: 16, name: '15 cm Electric Sparklers', per: '1 Box' },
    { id: 17, name: '30 cm Electric Sparklers', per: '1 Box' },
    { id: 18, name: '30 cm Colour Sparklers', per: '1 Box' },
    { id: 19, name: 'Ganga Jamuna (5’s)', per: '1 Box' },
    { id: 20, name: 'Princess Series', per: '1 Box' },
    { id: 21, name: 'Chit put (10‘s)', per: '1 Box' },
    { id: 22, name: '12 Shots', per: '1 Box' },
    { id: 23, name: 'Kutralam', per: '1 Box' },
    { id: 24, name: 'Motto', per: '1 Box' },
    { id: 25, name: 'SAMRAT Jumbo Deluxe 10’s', per: '1 Box' },
    { id: 26, name: 'Sun Drops (5’s)', per: '1 Box' },
    { id: 27, name: '7 Colour Shot (5’s)', per: '1 Box' },
    { id: 28, name: '2” Fancy Items (1 Pce)', per: '1 Box' },
    { id: 29, name: 'Emerald Robo (5’s)', per: '1 Box' },
    { id: 30, name: 'Chetak (Drone)', per: '1 Box' },
    { id: 31, name: 'Speed Bus', per: '1 Box' },
    { id: 32, name: 'Ostritch Egg', per: '1 Box' },
    { id: 33, name: 'Color Changing Butterfly (10’s)', per: '1 Box' },
    { id: 34, name: '20 Items Giftbox', per: '1 Box' },
    { id: 35, name: '4” Twinkling Star', per: '1 Box' }
  ];

  // Product List for Family Pack
  const familyPackProducts = [
  
      { id: 1, name: '4” Gold Lakshmi Crackers', per: 'Pkt' },
      { id: 2, name: '4” Lakshmi Crackers', per: 'Pkt' },
      { id: 3, name: '3½” Lakshmi Crackers', per: 'Pkt' },
      { id: 4, name: '5” Super Deluxe Lakshmi', per: 'Pkt' },
      { id: 5, name: 'Red Bijili (50’s)', per: 'Pkt' },
      { id: 6, name: 'Stripped Red Bijili (50’s)', per: 'Pkt' },
      { id: 7, name: 'Bullet Bomb (10’s)', per: 'Box' },
      { id: 8, name: 'Hydro Bomb (10’s)', per: 'Box' },
      { id: 9, name: 'Paper Bomb (1/4Kg)', per: 'Box' },
      { id: 10, name: 'Nasa Rocket', per: 'Box' },
      { id: 11, name: 'Flower Pots Big', per: 'Box' },
      { id: 12, name: 'Flower Pots Special', per: 'Box' },
      { id: 13, name: 'Color Koti', per: 'Box' },
      { id: 14, name: 'Rainbow Chakkaram Big', per: 'Box' },
      { id: 15, name: 'Rainbow Chakkaram Special', per: 'Box' },
      { id: 16, name: 'Rainbow Chakkaram Deluxe', per: 'Box' },
      { id: 17, name: '1½” Twinkling Star', per: 'Box' },
      { id: 18, name: '10 cm Electric Sparklers', per: 'Box' },
      { id: 19, name: '12 cm Electric Sparklers', per: 'Box' },
      { id: 20, name: '15 cm Electric Sparklers', per: 'Box' },
      { id: 21, name: '30 cm Electric Sparklers', per: 'Box' },
      { id: 22, name: '30 cm Colour Sparklers', per: 'Box' },
      { id: 23, name: 'Ganga Jamuna (5’s)', per: 'Box' },
      { id: 24, name: 'Princess Series', per: 'Box' },
      { id: 25, name: 'Chit put (10’s)', per: 'Box' },
      { id: 26, name: '12 Shots', per: 'Box' },
      { id: 27, name: 'Kutralam', per: 'Box' },
      { id: 28, name: 'Motto', per: 'Box' },
      { id: 29, name: 'SAMRAT Jumbo Deluxe 10’s', per: 'Box' },
      { id: 30, name: 'Sun Drops (5’s)', per: 'Box' },
      { id: 31, name: '7 Colour Shot (5’s)', per: 'Box' },
      { id: 32, name: '2” Fancy Items (1 Pce)', per: 'Box' },
      { id: 33, name: '3.5” Fancy Items (1 Pce)', per: 'Box' },
      { id: 34, name: 'Emerald Robo (5’s)', per: 'Box' },
      { id: 35, name: 'Sunsky Flash (5’s)', per: 'Box' },
      { id: 36, name: 'Chetak (Drone)', per: 'Box' },
      { id: 37, name: 'Speed Bus', per: 'Box' },
      { id: 38, name: 'Ostrich Egg', per: 'Box' },
      { id: 39, name: 'Bada Peacock', per: 'Box' },
      { id: 40, name: 'Color Changing Butterfly (10’s)', per: 'Box' },
      { id: 41, name: '20 Items Giftbox', per: 'Box' },
      { id: 42, name: '4” Twinkling Star', per: 'Box' },
    
    
  ];

  // Product List for Jumbo Pack
  const jumboPackProducts = [
    
      { id: 1, name: '4” Gold Lakshmi Crackers', per: '10 Pkt' },
      { id: 2, name: '4” Lakshmi Crackers', per: '10 Pkt' },
      { id: 3, name: '3½” Lakshmi Crackers', per: '10 Pkt' },
      { id: 4, name: '5” Super Deluxe Lakshmi', per: '1 Pkt' },
      { id: 5, name: 'Red Bijili (50’s)', per: '2 Pkt' },
      { id: 6, name: 'Stripped Red Bijili (50’s)', per: '2 Pkt' },
      { id: 7, name: 'Bullet Bomb (10’s)', per: '1 Box' },
      { id: 8, name: 'Hydro Bomb (10’s)', per: '1 Box' },
      { id: 9, name: 'Classic Bomb Green (10’s)', per: '1 Box' },
      { id: 10, name: 'King of Kingo Bomb', per: '1 Box' },
      { id: 11, name: 'Paper Bomb (1/4Kg)', per: '1 Box' },
      { id: 12, name: 'Paper Bomb (1/2Kg)', per: '1 Box' },
      { id: 13, name: 'Paper Bomb (1Kg)', per: '1 Box' },
      { id: 14, name: 'Nasa Rocket', per: '1 Box' },
      { id: 15, name: 'Lunik Rocket', per: '1 Box' },
      { id: 16, name: 'Flower Pots Big', per: '1 Box' },
      { id: 17, name: 'Flower Pots Special', per: '1 Box' },
      { id: 18, name: 'Color Koti', per: '1 Box' },
      { id: 19, name: 'Rangeela', per: '1 Box' },
      { id: 20, name: 'Rainbow Chakkaram Big', per: '1 Box' },
      { id: 21, name: 'Rainbow Chakkaram Special', per: '1 Box' },
      { id: 22, name: 'Rainbow Chakkaram Deluxe', per: '1 Box' },
      { id: 23, name: '1½” Twinkling Star', per: '1 Box' },
      { id: 24, name: '10 cm Electric Sparklers', per: '1 Box' },
      { id: 25, name: '12 cm Electric Sparklers', per: '1 Box' },
      { id: 26, name: '15 cm Electric Sparklers', per: '1 Box' },
      { id: 27, name: '15 cm Colour Sparklers', per: '1 Box' },
      { id: 28, name: '15 cm Green Sparklers', per: '1 Box' },
      { id: 29, name: '30 cm Electric Sparklers', per: '1 Box' },
      { id: 30, name: '30 cm Colour Sparklers', per: '1 Box' },
      { id: 31, name: '30 cm Green Sparklers', per: '1 Box' },
      { id: 32, name: '50 cm Electric Sparklers', per: '1 Box' },
      { id: 33, name: '50 cm Colour Sparklers', per: '1 Box' },
      { id: 34, name: 'Ganga Jamuna (5’s)', per: '1 Box' },
      { id: 35, name: 'Princess Series', per: '1 Box' },
      { id: 36, name: 'Chit put (10’s)', per: '1 Box' },
      { id: 37, name: '12 Shots', per: '1 Box' },
      { id: 38, name: '30 Shots', per: '1 Box' },
      { id: 39, name: '60 Shots', per: '1 Box' },
      { id: 40, name: 'Kutralam', per: '1 Box' },
      { id: 41, name: 'Motto', per: '1 Box' },
      { id: 42, name: 'CROWN Jumbo Classic 10’s', per: '1 Box' },
      { id: 43, name: 'Sun Drops (5’s)', per: '1 Box' },
      { id: 44, name: 'Paris Queen (10’s)', per: '1 Box' },
      { id: 45, name: 'Star King (1 Pce)', per: '1 Box' },
      { id: 46, name: 'Moon Drops (5’s)', per: '1 Box' },
      { id: 47, name: 'Chit put (10’s)', per: '1 Box' },
      { id: 48, name: 'Rock Star (2Pcs)', per: '1 Box' },
      { id: 49, name: '7 Colour Shot (5’s)', per: '1 Box' },
      { id: 50, name: '2” Fancy Items (1 Pce)', per: '1 Box' },
      { id: 51, name: '3.5” Fancy Items (1 Pce)', per: '1 Box' },
      { id: 52, name: '3.5” Fancy 7Steps', per: '1 Box' },
      { id: 53, name: '3.5” Fancy Double Balls', per: '1 Box' },
      { id: 54, name: 'Emerald Robo (5’s)', per: '1 Box' },
      { id: 55, name: 'Sunsky Flash (5’s)', per: '1 Box' },
      { id: 56, name: 'Chetak (Drone)', per: '1 Box' },
      { id: 57, name: 'Speed Bus', per: '1 Box' },
      { id: 58, name: 'Ostritch Egg', per: '1 Box' },
      { id: 59, name: 'Bada Peacock', per: '1 Box' },
      { id: 60, name: 'Color Changing Butterfly (10’s)', per: '1 Box' },
      { id: 61, name: '20 Items Giftbox', per: '1 Box' },
      { id: 62, name: '40 Items Giftbox', per: '1 Box' },
      { id: 63, name: '4” Twinkling Star', per: '1 Box' },
    
  ];

  return (
    <div className="home-page">
      <section className="hero">
        <h1 className="hero-title">Diwali 2024 Crackers Sale</h1>
        <p class="highlight-text">Get up to 50% off on all fireworks! Hurry, limited stock available.</p>

        <Link to="/ProductPage" className="cta-button">Shop Now</Link>
      </section>

      <section className="featured-products">
        <h2 class="highlight">Latest Products</h2>
        <div className="product-cards">
          <div className="product-card">
          
            <h3>Flower Spots</h3>
            <p>Up to 50% off on all types of Flower Spots.</p>
            <Link to="/ProductPage"  className="product-button">View More</Link>
          </div>
          <div className="product-card">
           
            <h3>Aerial display (4inch)</h3>
            <p>Exclusive discounts on Aerial display Crackers.</p>
            <Link to="/ProductPage" className="product-button">View More</Link>
          </div>
          <div className="product-card">
           
            <h3>Rainbow Chakkaram</h3>
            <p>Best quality Rainbow Chakkarams at discounted prices.</p>
            <Link to="/ProductPage"  className="product-button">View More</Link>
          </div>
        </div>
      </section>

      <Categories /> {/* Add Categories section here */}

      <section className="packs-section">
      <h2 className="Exclusive-pack">Exclusive Packs</h2>
        <div className="pack-cards">
          <div className="pack-card">
          
          <h2 className="kids-pack-title">Kids Pack</h2>
            <p class="h3-style">Rs. 3,000/-</p>

            <p>Perfect for children with safe and fun fireworks.</p>
            <button onClick={() => handleViewClick(kidsPackProducts)} className="product-button">View Details</button>
          </div>
          <div className="pack-card">
            
          <h2 className="kids-pack-title">Family Pack</h2>
            <p class="h3-style">Rs. 5,000/-</p>

            <p>A great variety for family celebrations.</p>
            <button onClick={() => handleViewClick(familyPackProducts)} className="product-button">View Details</button>
          </div>
          <div className="pack-card">
           
          <h2 className="kids-pack-title">Jumbo Pack</h2>
            <p class="h3-style">Rs. 10,000/-</p>

            <p>The ultimate collection for a grand Diwali celebration.</p>
            <button onClick={() => handleViewClick(jumboPackProducts)} className="product-button">View Details</button>
          </div>
        </div>
      </section>

      <Modal show={showModal} onClose={handleCloseModal}>
      <div className="modal-background">
      <h3 className="centered-ti">Crackers List</h3>

        <ul>
          {modalContent.map((product) => (
            <li key={product.id}>
              {product.id}. {product.name} - {product.per}
            </li>
          ))}
        </ul>
        </div>
      </Modal>
      
    </div>
    
  );
};

export default HomePage;
