import React from 'react';
import './SafetyTips.css';
import { Link } from 'react-router-dom';

const SafetyTips = () => {
  return (
    <div className="safety-tips-page">
       
      <header className="safety-header">
        <h1>Safety Tips</h1>
        <p>Celebrate Diwali safely with these important tips and precautions.</p>
       
      </header>

      <section className="general-tips">
        <h2>General Safety Guidelines</h2>
        
        <ul>
          <li>Always buy fireworks from licensed and reputable sellers.</li>
          <li>Read and follow all safety instructions on the packaging.</li>
          <li>Light fireworks in large, open spaces away from homes, vehicles, and flammable materials.</li>
          <li>Keep a bucket of water or fire extinguisher on hand in case of emergencies.</li>
          <li>Always supervise children closely when using fireworks.</li>
          <li>Wear safety goggles to protect your eyes.</li>
        </ul>
      </section>

      <section className="dos-and-donts">
        <div className="do-list">
          <h3>Do's</h3>
          <ul>
            <li>Light one firework at a time and stand back.</li>
            <li>Keep fireworks in a cool, dry place away from heat.</li>
            <li>Soak used fireworks in water before disposing of them.</li>
            <li>Keep pets indoors during fireworks shows.</li>
            <li>Use a flat, stable surface for lighting fireworks.</li>
          </ul>
        </div>

        <div className="dont-list">
          <h3>Don'ts</h3>
          <ul>
            
            <li>Don’t allow young children to handle fireworks.</li>
            <li>Don’t light fireworks indoors or near dry, flammable materials.</li>
            <li>Don’t point or throw fireworks at people or animals.</li>
            <li>Don’t carry fireworks in your pocket.</li>
          </ul>
        </div>
        
      </section>

      
    </div>
  );
};

export default SafetyTips;
