
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ProductPage.css';

const ShowProduct = () => {
  const products = [
    {
      id: 1,
      name: '4” Gold Lakshmi Crackers',
      content: '1 Box',
      actualPrice: 63.38,
      discountPrice: 31.69,
      image: 'path-to-image1.jpg',
    },
    {
      id: 2,
      name: '4”Lakshmi Crackers',
      content: '1 Box',
      actualPrice: 43.58,
      discountPrice: 21.79,
      image: 'path-to-image2.jpg',
    },
    {
      id: 3,
      name: '31/2” Lakshmi Crackers',
      content: '1 Box',
      actualPrice: 31.72,
      discountPrice: 15.86,
      image: 'path-to-image3.jpg',
    },
    {
      id: 4,
      name: '4” Deluxe Lakshmi',
      content: '1 Box',
      actualPrice: 60.84,
      discountPrice: 30.42,
      image: 'path-to-image4.jpg',
    },
    {
      id: 5,
      name: '5” Super Deluxe Lakshmi',
      content: '1 Box',
      actualPrice: 98.56,
      discountPrice: 49.28,
      image: 'path-to-image5.jpg',
    },
    {
      id: 6,
      name: 'Red Bijili (50’s)',
      content: '1 Box',
      actualPrice:51.52,
      discountPrice: 25.76,
      image: '',
    },
    {
      id: 7,
      name: 'Red Bijili (100’s)',
      content: '1 Box',
      actualPrice: 99.04,
      discountPrice: 49.52,
      image: 'path-to-image7.jpg',
    },
    {
      id: 8,
      name: 'Stripped Red Bijili (50’s)',
      content: '1 Box',
      actualPrice: 51.52,
      discountPrice: 25.76,
      image: 'path-to-image8.jpg',
    },
    {
      id: 9,
      name: 'Bullet Bomb (10’s)',
      content: '1 Box',
      actualPrice: 59.44,
      discountPrice: 29.72,
      image: 'path-to-image9.jpg',
    },
    {
      id: 10,
      name: 'Hydro Bomb (10’s)',
      content: '1 Box',
      actualPrice: 137.96,
      discountPrice: 68.98,
      image: 'path-to-image10.jpg',
    },
    {
      id: 11,
      name: 'Classic Bomb Green (10’s)',
      content: '1 Box',
      actualPrice: 211.32,
      discountPrice: 105.66,
      image: 'path-to-image11.jpg',
    },
    {
      id: 12,
      name: 'King of Kingo Bomb',
      content: '1 Box',
      actualPrice: 260.84,
      discountPrice: 130.42,
      image: 'path-to-image12.jpg',
    },
    {
      id: 13,
      name: 'Digital Bomb',
      content: '1 Box',
      actualPrice: 400.00,
      discountPrice: 200.00,
      image: 'path-to-image13.jpg',
    },
    {
      id: 14,
      name: 'King Raider',
      content: '1 Box',
      actualPrice: 500.00,
      discountPrice: 250.00,
      image: 'path-to-image14.jpg',
    },
    {
      id: 15,
      name: 'Paper Bomb (1/4Kg)',
      content: '1 Box',
      actualPrice: 91.40,
      discountPrice: 45.70,
      image: 'path-to-image15.jpg',
    },
    {
      id: 16,
      name: 'Paper Bomb (1/2Kg)',
      content: '1 Box',
      actualPrice: 182.80,
      discountPrice: 91.40,
      image: 'path-to-image16.jpg',
    },
    {
      id: 17,
      name: 'Paper Bomb (1Kg)',
      content: '1 Box',
      actualPrice: 291.40,
      discountPrice: 145.70,
      image: 'path-to-image17.jpg',
    },
    {
      id: 18,
      name: 'Color Paper Bomb',
      content: '1 Box',
      actualPrice: 110.72,
      discountPrice: 55.36,
      image: 'path-to-image18.jpg',
    },
    {
      id: 19,
      name: '5’s Paper Bomb',
      content: '1 Box',
      actualPrice: 308.00,
      discountPrice: 154.00,
      image: 'path-to-image19.jpg',
    },
    {
      id: 20,
      name: 'Money Rain (Day Out)',
      content: '1 Box',
      actualPrice: 291.06,
      discountPrice: 145.53,
      image: 'path-to-image20.jpg',
    },
    {
      id: 21,
      name: 'Nasa Rocket',
      content: '1 Box',
      actualPrice: 137.96,
      discountPrice: 68.98,
      image: 'path-to-image21.jpg',
    },
    {
      id: 22,
      name: 'Lunik Rocket',
      content: '1 Box',
      actualPrice: 275.92,
      discountPrice: 137.96,
      image: 'path-to-image22.jpg',
    },
    {
      id: 23,
      name: 'MI 2 (2 Sound)',
      content: '1 Box',
      actualPrice: 282.00,
      discountPrice: 141.00,
      image: 'path-to-image23.jpg',
    },
    {
      id: 24,
      name: 'MI 3 (3 Sound)',
      content: '1 Box',
      actualPrice: 303.52,
      discountPrice: 151.76,
      image: 'path-to-image24.jpg',
    },
    {
      id: 25,
      name: 'Rajthaji Express (Color Rocket)',
      content: '1 Box',
      actualPrice: 295.70,
      discountPrice: 147.85,
      image: 'path-to-image25.jpg',
    },
    {
      id: 26,
      name: 'Flower Pots Big',
      content: '1 Box',
      actualPrice: 150.24,
      discountPrice: 75.12,
      image: 'path-to-image26.jpg',
    },
    {
      id: 27,
      name: 'Flower Pots Special',
      content: '1 Box',
      actualPrice: 211.54,
      discountPrice: 105.77,
      image: 'path-to-image27.jpg',
    },
    {
      id: 28,
      name: 'Color Koti',
      content: '1 Box',
      actualPrice: 413.90,
      discountPrice: 206.95,
      image: 'path-to-image28.jpg',
    },
    {
      id: 29,
      name: 'Rangeela',
      content: '1 Box',
      actualPrice: 459.90,
      discountPrice: 229.95,
      image: 'path-to-image29.jpg',
    },
  
    {
      id: 30,
      name: 'Flower Pots Super Deluxe (5’s)',
      content: '1 Box',
      actualPrice: 459.90,
      discountPrice: 229.95,
      image: 'path-to-image30.jpg',
    },
    {
      id: 31,
      name: 'Ground Chakkaram Big',
      content: '1 Box',
      actualPrice: 88.92,
      discountPrice: 44.46,
      image: 'path-to-image31.jpg',
    },
    {
      id: 32,
      name: 'Ground Chakkaram Special',
      content: '1 Box',
      actualPrice: 159.42,
      discountPrice: 79.71,
      image: 'path-to-image32.jpg',
    },
    {
      id: 33,
      name: 'Ground Chakkaram Deluxe',
      content: '1 Box',
      actualPrice: 282.04,
      discountPrice: 141.02,
      image: 'path-to-image33.jpg',
    },
    {
      id: 34,
      name: 'Rainbow Chakkaram Big',
      content: '1 Box',
      actualPrice: 119.56,
      discountPrice: 59.78,
      image: 'path-to-image34.jpg',
    },
    {
      id: 35,
      name: 'Rainbow Chakkaram Special',
      content: '1 Box',
      actualPrice: 190.08,
      discountPrice: 95.04,
      image: 'path-to-image35.jpg',
    },
    {
      id: 36,
      name: 'Rainbow Chakkaram Deluxe',
      content: '1 Box',
      actualPrice: 312.72,
      discountPrice: 156.36,
      image: 'path-to-image36.jpg',
    },
    {
      id: 37,
      name: 'Zoom Wheel (3’s)',
      content: '1 Box',
      actualPrice: 224.72,
      discountPrice: 112.36,
      image: 'path-to-image37.jpg',
    },
    {
      id: 38,
      name: 'Dollar Wheel (5’s)',
      content: '1 Box',
      actualPrice: 228.72,
      discountPrice: 114.36,
      image: 'path-to-image38.jpg',
    },
    {
      id: 39,
      name: 'Musical Wheel (5’s)',
      content: '1 Box',
      actualPrice: 224.72,
      discountPrice: 112.36,
      image: 'path-to-image39.jpg',
    },
    {
      id: 40,
      name: 'Dragon Wheel (1’s)',
      content: '1 Box',
      actualPrice: 260.90,
      discountPrice: 130.45,
      image: 'path-to-image40.jpg',
    },
    {
      id: 41,
      name: 'Funtastic Wheel (5’s)',
      content: '1 Box',
      actualPrice: 185.94,
      discountPrice: 92.97,
      image: 'path-to-image41.jpg',
    },
    {
      id: 42,
      name: 'Free Fire Candle',
      content: '1 Box',
      actualPrice: 100.00,
      discountPrice: 50.00,
      image: 'path-to-image42.jpg',
    },
    {
      id: 43,
      name: '15” Party Candles (10’s)',
      content: '1 Box',
      actualPrice: 229.84,
      discountPrice: 114.92,
      image: 'path-to-image43.jpg',
    },
    {
      id: 44,
      name: 'Crackling Candle (2’s)',
      content: '1 Box',
      actualPrice: 440.00,
      discountPrice: 220.00,
      image: 'path-to-image44.jpg',
    },
    {
      id: 45,
      name: '1½” Twinkling Star',
      content: '1 Box',
      actualPrice: 61.32,
      discountPrice: 30.66,
      image: 'path-to-image45.jpg',
    },
    {
      id: 46,
      name: '4” Twinkling Star',
      content: '1 Box',
      actualPrice: 168.62,
      discountPrice: 84.31,
      image: 'path-to-image46.jpg',
    },
    {
      id: 47,
      name: 'Jil Jil',
      content: '1 Box',
      actualPrice: 65.32,
      discountPrice: 32.66,
      image: 'path-to-image47.jpg',
    },
    {
      id: 48,
      name: '10 cm Electric Sparklers',
      content: '1 Box',
      actualPrice: 31.82,
      discountPrice: 15.91,
      image: 'path-to-image48.jpg',
    },
    {
      id: 49,
      name: '10 cm Colour Sparklers',
      content: '1 Box',
      actualPrice: 34.90,
      discountPrice: 17.45,
      image: 'path-to-image49.jpg',
    },
    {
      id: 50,
      name: '10 cm Green Sparklers',
      content: '1 Box',
      actualPrice: 40.00,
      discountPrice: 20.00,
      image: 'path-to-image50.jpg',
    },
    {
      id: 51,
      name: '10 cm Red Sparklers',
      content: '1 Box',
      actualPrice: 43.12,
      discountPrice: 21.56,
      image: 'path-to-image51.jpg',
    },
    {
      id: 52,
      name: '12 cm Electric Sparklers',
      content: '1 Box',
      actualPrice: 44.46,
      discountPrice: 22.23,
      image: 'path-to-image52.jpg',
    },
    {
      id: 53,
      name: '12 cm Colour Sparklers',
      content: '1 Box',
      actualPrice: 47.74,
      discountPrice: 23.87,
      image: 'path-to-image53.jpg',
    },
    {
      id: 54,
      name: '12 cm Green Sparklers',
      content: '1 Box',
      actualPrice: 50.82,
      discountPrice: 25.41,
      image: 'path-to-image54.jpg',
    },
    {
      id: 55,
      name: '12 cm Red Sparklers',
      content: '1 Box',
      actualPrice: 53.90,
      discountPrice: 26.95,
      image: 'path-to-image55.jpg',
    },
    {
      id: 56,
      name: '15 cm Electric Sparklers',
      content: '1 Box',
      actualPrice: 69.30,
      discountPrice: 34.65,
      image: 'path-to-image56.jpg',
    },
    {
      id: 57,
      name: '15 cm Colour Sparklers',
      content: '1 Box',
      actualPrice: 72.38,
      discountPrice: 36.19,
      image: 'path-to-image57.jpg',
    },
    {
      id: 58,
      name: '15 cm Green Sparklers',
      content: '1 Box',
      actualPrice: 77.00,
      discountPrice: 38.50,
      image: 'path-to-image58.jpg',
    },
    {
      id: 59,
      name: '15 cm Red Sparklers',
      content: '1 Box',
      actualPrice: 86.24,
      discountPrice: 43.12,
      image: 'path-to-image59.jpg',
    },
    {
      id: 60,
      name: '30 cm Electric Sparklers',
      content: '1 Box',
      actualPrice: 69.30,
      discountPrice: 34.65,
      image: 'path-to-image60.jpg',
    },
   
      {
        id: 61,
        name: '30 cm Colour Sparklers',
        content: '1 Box',
        actualPrice: 72.38,
        discountPrice: 36.19,
        image: 'path-to-image61.jpg',
      },
      {
        id: 62,
        name: '30 cm Green Sparklers',
        content: '1 Box',
        actualPrice: 77.00,
        discountPrice: 38.50,
        image: 'path-to-image62.jpg',
      },
      {
        id: 63,
        name: '30 cm Red Sparklers',
        content: '1 Box',
        actualPrice: 86.24,
        discountPrice: 43.12,
        image: 'path-to-image63.jpg',
      },
      {
        id: 64,
        name: '50 cm Electric Sparklers',
        content: '1 Box',
        actualPrice: 277.20,
        discountPrice: 138.60,
        image: 'path-to-image64.jpg',
      },
      {
        id: 65,
        name: '50 cm Colour Sparklers',
        content: '1 Box',
        actualPrice: 292.60,
        discountPrice: 146.30,
        image: 'path-to-image65.jpg',
      },
      {
        id: 66,
        name: 'Kit Kat',
        content: '1 Box',
        actualPrice: 50.60,
        discountPrice: 25.30,
        image: 'path-to-image66.jpg',
      },
      {
        id: 67,
        name: 'Ganga Jamuna (5’s)',
        content: '1 Box',
        actualPrice: 135.36,
        discountPrice: 67.68,
        image: 'path-to-image67.jpg',
      },
      {
        id: 68,
        name: 'Princess Series',
        content: '1 Box',
        actualPrice: 148.58,
        discountPrice: 74.29,
        image: 'path-to-image68.jpg',
      },
      {
        id: 69,
        name: 'Chit put (10’s)',
        content: '1 Box',
        actualPrice: 151.88,
        discountPrice: 75.94,
        image: 'path-to-image69.jpg',
      },
      {
        id: 70,
        name: 'Star King (1 Pce)',
        content: '1 Box',
        actualPrice: 222.80,
        discountPrice: 111.40,
        image: 'path-to-image70.jpg',
      },
      {
        id: 71,
        name: 'Sun Drops (5’s)',
        content: '1 Box',
        actualPrice: 181.60,
        discountPrice: 90.80,
        image: 'path-to-image71.jpg',
      },
      {
        id: 72,
        name: 'Moon Drops (5’s)',
        content: '1 Box',
        actualPrice: 165.08,
        discountPrice: 82.54,
        image: 'path-to-image72.jpg',
      },
      {
        id: 73,
        name: 'Shin Chan',
        content: '1 Box',
        actualPrice: 160.00,
        discountPrice: 80.00,
        image: 'path-to-image73.jpg',
      },
      {
        id: 74,
        name: '90 Watts',
        content: '1 Box',
        actualPrice: 180.00,
        discountPrice: 90.00,
        image: 'path-to-image74.jpg',
      },
      {
        id: 75,
        name: 'Chetak (Drone)',
        content: '1 Box',
        actualPrice: 210.00,
        discountPrice: 105.00,
        image: 'path-to-image75.jpg',
      },
      {
        id: 76,
        name: 'Color Changing Butterfly (10’s)',
        content: '1 Box',
        actualPrice: 270.72,
        discountPrice: 135.36,
        image: 'path-to-image76.jpg',
      },
      {
        id: 77,
        name: 'Helicopter',
        content: '1 Box',
        actualPrice: 330.00,
        discountPrice: 165.00,
        image: 'path-to-image77.jpg',
      },
      {
        id: 78,
        name: 'Tin Fountain',
        content: '1 Box',
        actualPrice: 246.40,
        discountPrice: 123.20,
        image: 'path-to-image78.jpg',
      },
      {
        id: 79,
        name: 'Bambaram',
        content: '1 Box',
        actualPrice: 198.10,
        discountPrice: 99.05,
        image: 'path-to-image79.jpg',
      },
      {
        id: 80,
        name: 'Sunsky Flash (5’s)',
        content: '1 Box',
        actualPrice: 349.98,
        discountPrice: 174.99,
        image: 'path-to-image80.jpg',
      },
      {
        id: 81,
        name: 'Dragon Tail (5’s)',
        content: '1 Box',
        actualPrice: 254.22,
        discountPrice: 127.11,
        image: 'path-to-image81.jpg',
      },
      {
        id: 82,
        name: 'Pyro Dancing (10’s)',
        content: '1 Box',
        actualPrice: 260.00,
        discountPrice: 130.00,
        image: 'path-to-image82.jpg',
      },
      {
        id: 83,
        name: 'Ruby Robo (5’s)',
        content: '1 Box',
        actualPrice: 272.40,
        discountPrice: 136.20,
        image: 'path-to-image83.jpg',
      },
      {
        id: 84,
        name: 'Emerald Robo (5’s)',
        content: '1 Box',
        actualPrice: 272.40,
        discountPrice: 136.20,
        image: 'path-to-image84.jpg',
      },
      {
        id: 85,
        name: 'Diamond Robo (5’s)',
        content: '1 Box',
        actualPrice: 272.40,
        discountPrice: 136.20,
        image: 'path-to-image85.jpg',
      },
      {
        id: 86,
        name: 'Ping Pong (5’s)',
        content: '1 Box',
        actualPrice: 290.00,
        discountPrice: 145.00,
        image: 'path-to-image86.jpg',
      },
      {
        id: 87,
        name: 'Lollipops',
        content: '1 Box',
        actualPrice: 330.00,
        discountPrice: 165.00,
        image: 'path-to-image87.jpg',
      },
      {
        id: 88,
        name: 'Snow Ball',
        content: '1 Box',
        actualPrice: 295.68,
        discountPrice: 147.84,
        image: 'path-to-image88.jpg',
      },
      {
        id: 89,
        name: '4*4 Wheel',
        content: '1 Box',
        actualPrice: 320.32,
        discountPrice: 160.16,
        image: 'path-to-image89.jpg',
      },
      {
        id: 90,
        name: 'Planet Wheel',
        content: '1 Box',
        actualPrice: 380.00,
        discountPrice: 190.00,
        image: 'path-to-image90.jpg',
      },
      {
        id: 91,
        name: 'Paris Queen (10’s)',
        content: '1 Box',
        actualPrice: 396.20,
        discountPrice: 198.10,
        image: 'path-to-image91.jpg',
      },
      {
        id: 92,
        name: 'Old Is Gold',
        content: '1 Box',
        actualPrice: 400.00,
        discountPrice: 200.00,
        image: 'path-to-image92.jpg',
      },
      {
        id: 93,
        name: 'Siren (2’s)',
        content: '1 Box',
        actualPrice: 528.28,
        discountPrice: 264.14,
        image: 'path-to-image93.jpg',
      },
      {
        id: 94,
        name: 'Air Gel (10’s)',
        content: '1 Box',
        actualPrice: 460.00,
        discountPrice: 230.00,
        image: 'path-to-image94.jpg',
      },
      {
        id: 95,
        name: 'Flora (Cone Fountains)',
        content: '1 Box',
        actualPrice: 495.26,
        discountPrice: 247.63,
        image: 'path-to-image95.jpg',
      },
      {
        id: 96,
        name: 'Tin Fountain (3’s)',
        content: '1 Box',
        actualPrice: 739.20,
        discountPrice: 369.60,
        image: 'path-to-image96.jpg',
      },
      {
        id: 97,
        name: 'Rock Star (2 Pcs)',
        content: '1 Box',
        actualPrice: 660.36,
        discountPrice: 330.18,
        image: 'path-to-image97.jpg',
      },
      {
        id: 98,
        name: 'Cocktail Fountains (3’s)',
        content: '1 Box',
        actualPrice: 990.52,
        discountPrice: 495.26,
        image: 'path-to-image98.jpg',
      },
      {
        id: 99,
        name: 'Tri Colour Fancy Shower',
        content: '1 Box',
        actualPrice: 400.00,
        discountPrice: 200.00,
        image: 'path-to-image99.jpg',
      },
      {
        id: 100,
        name: 'Mojito (2’s)',
        content: '1 Box',
        actualPrice: 280.00,
        discountPrice: 140.00,
        image: 'path-to-image100.jpg',
      },
      {
        id: 101,
        name: 'Bada Peacock',
        content: '1 Box',
        actualPrice: 816.00,
        discountPrice: 408.00,
        image: 'path-to-image101.jpg',
      },
        {
          id: 102,
          name: 'Peacock',
          content: '1 Box',
          actualPrice: 340.00,
          discountPrice: 170.00,
          image: 'path-to-image102.jpg',
        },
        {
          id: 103,
          name: 'Cocktail 5 in 1',
          content: '1 Box',
          actualPrice: 1281.28,
          discountPrice: 640.64,
          image: 'path-to-image103.jpg',
        },
        {
          id: 104,
          name: 'Chotta Bheem',
          content: '1 Box',
          actualPrice: 40.00,
          discountPrice: 20.00,
          image: 'path-to-image104.jpg',
        },
        {
          id: 105,
          name: '7 Colour Shot (5’s)',
          content: '1 Box',
          actualPrice: 254.24,
          discountPrice: 127.12,
          image: 'path-to-image105.jpg',
        },
        {
          id: 106,
          name: '2” Fancy Items (1 Pce)',
          content: '1 Box',
          actualPrice: 346.68,
          discountPrice: 173.34,
          image: 'path-to-image106.jpg',
        },
        {
          id: 107,
          name: '2” Fancy Items (3 Pcs)',
          content: '1 Box',
          actualPrice: 455.40,
          discountPrice: 227.70,
          image: 'path-to-image107.jpg',
        },
        {
          id: 108,
          name: '2” Fancy Tail Function (3 Pcs)',
          content: '1 Box',
          actualPrice: 600.00,
          discountPrice: 300.00,
          image: 'path-to-image108.jpg',
        },
        {
          id: 109,
          name: '3.5” Fancy Items (1 Pce)',
          content: '1 Box',
          actualPrice: 594.32,
          discountPrice: 297.16,
          image: 'path-to-image109.jpg',
        },
        {
          id: 110,
          name: '3.5” Fancy Items (2 Pcs)',
          content: '1 Box',
          actualPrice: 1188.64,
          discountPrice: 594.32,
          image: 'path-to-image110.jpg',
        },
        {
          id: 111,
          name: '3.5” Fancy Web Crackling (2 Pcs)',
          content: '1 Box',
          actualPrice: 1638.56,
          discountPrice: 819.28,
          image: 'path-to-image111.jpg',
        },
        {
          id: 112,
          name: '3.5” Fancy 7Steps',
          content: '1 Box',
          actualPrice: 792.42,
          discountPrice: 396.21,
          image: 'path-to-image112.jpg',
        },
        {
          id: 113,
          name: '3.5” Fancy Double Balls',
          content: '1 Box',
          actualPrice: 924.50,
          discountPrice: 462.25,
          image: 'path-to-image113.jpg',
        },
        {
          id: 114,
          name: '4” Fancy Items (1 Pce)',
          content: '1 Box',
          actualPrice: 1056.56,
          discountPrice: 528.28,
          image: 'path-to-image114.jpg',
        },
        {
          id: 115,
          name: '4” Fancy Items (2 Pcs)',
          content: '1 Box',
          actualPrice: 1798.72,
          discountPrice: 899.36,
          image: 'path-to-image115.jpg',
        },
        {
          id: 116,
          name: '12 Shots',
          content: '1 Box',
          actualPrice: 357.28,
          discountPrice: 178.64,
          image: 'path-to-image116.jpg',
        },
        {
          id: 117,
          name: '12 Shots Raider',
          content: '1 Box',
          actualPrice: 357.28,
          discountPrice: 178.64,
          image: 'path-to-image117.jpg',
        },
        {
          id: 118,
          name: '25 Shots',
          content: '1 Box',
          actualPrice: 677.60,
          discountPrice: 338.80,
          image: 'path-to-image118.jpg',
        },
        {
          id: 119,
          name: '30 Shots',
          content: '1 Box',
          actualPrice: 924.00,
          discountPrice: 462.00,
          image: 'path-to-image119.jpg',
        },
        {
          id: 120,
          name: '50 Shots',
          content: '1 Box',
          actualPrice: 1355.20,
          discountPrice: 677.60,
          image: 'path-to-image120.jpg',
        },
        {
          id: 121,
          name: '60 Shots',
          content: '1 Box',
          actualPrice: 1971.20,
          discountPrice: 985.60,
          image: 'path-to-image121.jpg',
        },
        {
          id: 122,
          name: '100 Shots',
          content: '1 Box',
          actualPrice: 3597.44,
          discountPrice: 1798.72,
          image: 'path-to-image122.jpg',
        },
        {
          id: 123,
          name: 'Top 10*10 Gun Shots',
          content: '1 Box',
          actualPrice: 6400.00,
          discountPrice: 3200.00,
          image: 'path-to-image123.jpg',
        },
        {
          id: 124,
          name: '120 Shots',
          content: '1 Box',
          actualPrice: 3696.00,
          discountPrice: 1848.00,
          image: 'path-to-image124.jpg',
        },
        {
          id: 125,
          name: '240 Shots',
          content: '1 Box',
          actualPrice: 7392.00,
          discountPrice: 3696.00,
          image: 'path-to-image125.jpg',
        },
        {
          id: 126,
          name: '12 Shots',
          content: '1 Box',
          actualPrice: 429.24,
          discountPrice: 214.62,
          image: 'path-to-image126.jpg',
        },
        {
          id: 127,
          name: '25 Shots',
          content: '1 Box',
          actualPrice: 1386.74,
          discountPrice: 693.37,
          image: 'path-to-image127.jpg',
        },
        {
          id: 128,
          name: '30 Shots',
          content: '1 Box',
          actualPrice: 1551.82,
          discountPrice: 775.91,
          image: 'path-to-image128.jpg',
        },
        {
          id: 129,
          name: '60 Shots',
          content: '1 Box',
          actualPrice: 2608.40,
          discountPrice: 1304.20,
          image: 'path-to-image129.jpg',
        },
        {
          id: 130,
          name: 'Calvanic Repeating Shots',
          content: '1 Box',
          actualPrice: 3962.20,
          discountPrice: 1981.10,
          image: 'path-to-image130.jpg',
        },
        {
          id: 131,
          name: '120 Shots',
          content: '1 Box',
          actualPrice: 4622.46,
          discountPrice: 2311.23,
          image: 'path-to-image131.jpg',
        },
        {
          id: 132,
          name: '240 Shots',
          content: '1 Box',
          actualPrice: 8914.76,
          discountPrice: 4457.38,
          image: 'path-to-image132.jpg',
        },
        {
          id: 133,
          name: 'Ring Cap Gun',
          content: '1 Box',
          actualPrice: 120.00,
          discountPrice: 60.00,
          image: 'path-to-image133.jpg',
        },
        {
          id: 134,
          name: 'Ring Cap (10’s)',
          content: '1 Box',
          actualPrice: 200.00,
          discountPrice: 100.00,
          image: 'path-to-image134.jpg',
        },
        {
          id: 135,
          name: 'Normal Gun',
          content: '1 Pce',
          actualPrice: 100.00,
          discountPrice: 50.00,
          image: 'path-to-image135.jpg',
        },
        {
          id: 136,
          name: 'Roll Cap',
          content: '1 Roll',
          actualPrice: 140.00,
          discountPrice: 70.00,
          image: 'path-to-image136.jpg',
        },
        {
          id: 137,
          name: 'GARUDA Tiny (10’s)',
          content: '1 Box',
          actualPrice: 60.00,
          discountPrice: 30.00,
          image: 'path-to-image137.jpg',
        },
        {
          id: 138,
          name: 'ANNAM Deluxe (10’s)',
          content: '1 Box',
          actualPrice: 156.00,
          discountPrice: 78.00,
          image: 'path-to-image138.jpg',
        },
        {
          id: 139,
          name: 'SAMRAT Mishmash (5’s)',
          content: '1 Box',
          actualPrice: 180.00,
          discountPrice: 90.00,
          image: 'path-to-image139.jpg',
        },
        {
          id: 140,
          name: 'SAMRAT Jumbo Deluxe (10’s)',
          content: '1 Box',
          actualPrice: 240.00,
          discountPrice: 120.00,
          image: 'path-to-image140.jpg',
        },
        {
          id: 141,
          name: 'CROWN Jumbo Classic (10’s)',
          content: '1 Box',
          actualPrice: 408.00,
          discountPrice: 204.00,
          image: 'path-to-image141.jpg',
        },
        {
          id: 142,
          name: '20 Items',
          content: '1 Box',
          actualPrice: 440.00,
          discountPrice: 220.00,
          image: 'path-to-image142.jpg',
        },
        {
          id: 143,
          name: '25 Items',
          content: '1 Box',
          actualPrice: 600.00,
          discountPrice: 300.00,
          image: 'path-to-image143.jpg',
        },
        {
          id: 144,
          name: '30 Items',
          content: '1 Box',
          actualPrice: 800.00,
          discountPrice: 400.00,
          image: 'path-to-image144.jpg',
        },
        {
          id: 145,
          name: '35 Items',
          content: '1 Box',
          actualPrice: 960.00,
          discountPrice: 480.00,
          image: 'path-to-image145.jpg',
        },
        {
          id: 146,
          name: '40 Items',
          content: '1 Box',
          actualPrice: 1100.00,
          discountPrice: 550.00,
          image: 'path-to-image146.jpg',
        },
        {
          id: 147,
          name: '50 Items',
          content: '1 Box',
          actualPrice: 1700.00,
          discountPrice: 850.00,
          image: 'path-to-image147.jpg',
        },
        {
          id: 148,
          name: 'Ostritch Egg',
          content: '1 Box',
          actualPrice: 412.40,
          discountPrice: 206.20,
          image: 'path-to-image148.jpg',
        },
        {
          id: 149,
          name: 'Kutralam',
          content: '1 Box',
          actualPrice: 222.06,
          discountPrice: 111.03,
          image: 'path-to-image149.jpg',
        },
        {
          id: 150,
          name: 'Speed Bus',
          content: '1 Box',
          actualPrice: 507.60,
          discountPrice: 253.80,
          image: 'path-to-image150.jpg',
        },
        {
          id: 151,
          name: 'Niagara',
          content: '1 Box',
          actualPrice: 500.00,
          discountPrice: 250.00,
          image: 'path-to-image151.jpg',
        },
        {
          id: 152,
          name: 'Motto',
          content: '1 Box',
          actualPrice: 339.90,
          discountPrice: 169.95,
          image: 'path-to-image152.jpg',
        },
        {
          id: 153,
          name: 'Mizpah',
          content: '1 Box',
          actualPrice: 400.00,
          discountPrice: 200.00,
          image: 'path-to-image153.jpg',
        },
        {
          id: 154,
          name: 'Photo Flash',
          content: '1 Box',
          actualPrice: 231.12,
          discountPrice: 115.56,
          image: 'path-to-image154.jpg',
        },
        {
          id: 155,
          name: '2 ¾” Kuruvi',
          content: '1 Pkt',
          actualPrice: 23.56,
          discountPrice: 11.78,
          image: 'path-to-image155.jpg',
        },
        {
          id: 156,
          name: 'Bubble Bubble',
          content: '1 Box',
          actualPrice: 858.46,
          discountPrice: 429.23,
          image: 'path-to-image156.jpg',
        },
        {
          id: 157,
          name: 'Smoke (3’s)',
          content: '1 Box',
          actualPrice: 309.00,
          discountPrice: 154.50,
          image: 'path-to-image157.jpg',
        },
        {
          id: 158,
          name: 'Fish',
          content: '1 Box',
          actualPrice: 299.94,
          discountPrice: 149.97,
          image: 'path-to-image158.jpg',
        },
        {
          id: 159,
          name: 'Min Mini',
          content: '1 Box',
          actualPrice: 374.92,
          discountPrice: 187.46,
          image: 'path-to-image159.jpg',
        },
        {
          id: 160,
          name: 'Kinder Joy',
          content: '1 Box',
          actualPrice: 139.05,
          discountPrice: 69.525,
          image: 'path-to-image160.jpg',
        },
        {
          id: 161,
          name: 'Smoke Magic Gold',
          content: '1 Box',
          actualPrice: 309.00,
          discountPrice: 154.50,
          image: 'path-to-image161.jpg',
        },
        {
          id: 162,
          name: 'Goa 28',
          content: '1 Unit',
          actualPrice: 40.00,
          discountPrice: 20.00,
          image: 'path-to-image162.jpg',
        },
        {
          id: 163,
          name: 'Deluxe 24',
          content: '1 Unit',
          actualPrice: 136.00,
          discountPrice: 68.00,
          image: 'path-to-image163.jpg',
        },
        {
          id: 164,
          name: '1 Access',
          content: '1 Unit',
          actualPrice: 500.00,
          discountPrice: 250.00,
          image: 'path-to-image164.jpg',
        },
        {
          id: 165,
          name: '2 Ct100',
          content: '1 Unit',
          actualPrice: 1000.00,
          discountPrice: 500.00,
          image: 'path-to-image165.jpg',
        },
        {
          id: 166,
          name: '5 Splender',
          content: '1 Unit',
          actualPrice: 2600.00,
          discountPrice: 1300.00,
          image: 'path-to-image166.jpg',
        },
        
     
      
    
  ];

  const [cart, setCart] = useState({});
  const [totalProducts, setTotalProducts] = useState(0);
  const [overallTotal, setOverallTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleQuantityChange = (id, discountPrice, quantity) => {
    const updatedCart = { ...cart, [id]: quantity };
    setCart(updatedCart);

    let total = 0;
    let productsCount = 0;

    Object.keys(updatedCart).forEach((productId) => {
      const qty = updatedCart[productId];
      const product = products.find((p) => p.id === parseInt(productId));
      if (qty && product) {
        total += product.discountPrice * qty;
        productsCount += qty;
      }
    });

    setTotalProducts(productsCount);
    setOverallTotal(total);
  };

  const handleProceedToPay = () => {
    const selectedItems = products.filter((product) => cart[product.id]);
    navigate('/checkout', { state: { cart, selectedItems, overallTotal } });
  };
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="product-page">
      <h1>List of Products</h1>

      {/* Search Input */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search for crackers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      <div className="content-container">
        <div className="cart-summary">
          <p>Total Products: {totalProducts}</p>
          <p>Total Amount: Rs {overallTotal.toFixed(2)}</p>
          
        <button onClick={handleProceedToPay} disabled={totalProducts === 0}>
          Proceed to Pay
        </button>
      
        </div>

        <div className="table-responsive">
    <table>
        <thead>
            <tr>
            <th>Id</th>
                <th>Name</th>
               
                <th>Actual Price</th>
                <th>Discount Price</th>
                <th>Quantity</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            {filteredProducts.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                    <td>{product.name}</td>
                    
                    <td className="price-content-cell">
  <s>Rs {product.actualPrice}</s>
  <br />
  <span className="content-count">({product.content})</span>
</td>
                    <td>{product.discountPrice}</td>
                    <td>
                        <input
                            type="number"
                            min="0"
                            value={cart[product.id] || ''}
                            onChange={(e) =>
                                handleQuantityChange(product.id, product.discountPrice, parseInt(e.target.value) || 0)
                            }
                            className="quantity-input"
                        />
                    </td>
                    <td>{((cart[product.id] || 0) * product.discountPrice).toFixed(2)}</td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

      </div>

  

     
    </div>
  );
};

export default ShowProduct;
