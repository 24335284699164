import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ShowProduct from './components/ProductPage'; 
import SafetyTips from './components/SafetyTips';
import ContactUs from './components/ContactUs';
import Header from './components/Header';
import Checkout from './components/CheckoutPage';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';

function App() {
  return (
    <div className="App">
      <div>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ProductPage" element={<ShowProduct />} />
        <Route path="/SafetyTips" element={<SafetyTips />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
      <Footer />
      <WhatsAppButton />
    </div>
    </div>
  );
}



export default App;
