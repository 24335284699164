import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';  // Import slick carousel styles
import 'slick-carousel/slick/slick-theme.css';
import './Categories.css';  // Import your custom CSS for styling

const Categories = () => {
  const products = [
    { id: 1, name: "Sparklers", img: "../assets/10.png" },
    { id: 2, name: "Flower Pots", img: "https://images.unsplash.com/photo-1604391287268-81c9bca5fa2d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" },
    { id: 3, name: "Ground Chakkar", img: "https://images.unsplash.com/photo-1504286363440-5164a03f792a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" },
    { id: 4, name: "Sky Shots", img: "https://images.unsplash.com/photo-1605627920945-6baf0bf801c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" },
    { id: 5, name: "Rocket", img: "https://images.unsplash.com/photo-1507007778724-acef42db68e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" },
    { id: 6, name: "Atom Bomb", img: "https://images.unsplash.com/photo-1610238599707-5a6dc69d5463?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" },
    { id: 7, name: "Twinkling Stars", img: "https://images.unsplash.com/photo-1519677464335-a827d597b380?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" },
    { id: 8, name: "Catherine Wheel", img: "https://images.unsplash.com/photo-1603735946221-bd1ed0a7d791?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400" }
];;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true, // Enable auto-play
    autoplaySpeed: 3000, // Set duration for each slide
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="categories-container">
      <h2 className="Categories-title">Categories</h2>
      <p>Best quality and quantity crackers.</p>
      <Slider {...settings}>
        {products.map((product) => (
          <div className="product-card" key={product.id}>
           
            <h3>{product.name}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Categories;
